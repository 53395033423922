import React, { useContext, useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import axios from "axios";
import AuthContext from "../../../../hooks/useAuth";
import { ThemePreferenceContext } from "../../../../index";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MetricUsage from "../../Pages/Sessions/MetricUsage";
import "./Sharing.css";

import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

export default function Home(props) {
  const [uid, setUid] = useState(null);
  const auth = useContext(AuthContext);
  const { currentTheme } = useContext(ThemePreferenceContext);
  const [sessionData, setSessionData] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notes, setNotes] = useState([]);
  const open = Boolean(anchorEl);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [timeStamp, setTimeStamp] = useState("");
  const [timeNull, setTimeNull] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [sessionsId, setSessionsId] = useState("");
  const [epochTime, setEpochTime] = useState("");
  const [xAxisParam, setXAxisParam] = useState("time");
  const [isGraphReady, setIsGraphReady] = useState(false); // New state to control graph rendering

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const sessionId = params.get("sessionId");
    const epochTime = params.get("idn");

    if (sessionId && epochTime) {
      setSessionsId(sessionId);
      setEpochTime(epochTime);
      fetchSessionData(sessionId, epochTime);
    }
  }, []);

  const fetchSessionData = async (sessionId, epochTime) => {
    try {
      const response = await axios.get(
        `https://gamemondev-service.indiumsoft.com/shareSessionDetails?sessionId=${sessionId}&idn=${epochTime}`
      );
      if (response.data.status && response.data.data.length > 0) {
        const data = response.data.data[0];
        setSessionData(data);
        setUid(data.user_id);
        fetchSessionNotes(sessionId);

        // Convert gpu_usage_time to seconds based on total duration
        const totalDuration = Number(data.total_duration) || 0;
        const timePoints = Array.from(
          { length: data.gpu_usage_time.length },
          (_, i) => (i * totalDuration) / data.gpu_usage_time.length
        );
        data.gpu_usage_time_in_seconds = timePoints; // Add new field for seconds
      } else {
        toast.error("No session data found!");
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
      toast.error("Failed to load session data!");
    }
  };

  const fetchSessionNotes = async (sessionId) => {
    try {
      const response = await axios.get("/fetchallSessionNotes", {
        params: { sessionID: sessionId },
      });
      setNotes(response.data.data || []);
    } catch (error) {
      console.error("Error fetching session notes:", error);
      setNotes([]);
    }
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
    setRotationAngle(0);
  };
  const rotateImage = () => setRotationAngle(rotationAngle - 90);

  const opener = async () => {
    const response = await fetch(

      `https://gamemondev-service.indiumsoft.com/downloadlogs?fileName=${sessionData?.session_id}`
    );
    if (response.status === 200) {
      window.open(
        `https://gamemondev-service.indiumsoft.com/downloadlogs?fileName=${sessionData?.session_id}`

      );
    } else {
      toast.error("No Log File for this Session!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const screenshot = (xaxis) => {
    if (sessionData?.screenshot_timestamp) {
      const value = sessionData.gpu_usage_time.indexOf(xaxis);
      if (value !== -1) {
        const img = `https://gamemondev-service.indiumsoft.com/getImage/?filename=${sessionData.session_id}-${sessionData.screenshot_timestamp[value]}.png`;
        setTimeNull(sessionData.screenshot_timestamp[value]);
        setTimeStamp(img);
        setIsLoading(true);
      }
    }
  };

// <<<<<<< ashrith
  const handleZoomIconClick = () => {
    if (imageLoaded) {
      openModal(timeStamp);
    } else {
      setTimeNull("a");
      toast.error("Kindly pick an image to explore!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
// =======
//     if (newArray.length !== 0) {
//       const value = Secondsessiondata?.gpu_usage_time.indexOf(xaxis);

//       const img = `https://gamemon-service.indiumsoft.com/getImage/?filename=${Secondsessiondata?.session_id}-${Secondsessiondata?.screenshot_timestamp[value]}.png`;

//       console.log("images", img);
//       setTimenull(Secondsessiondata?.screenshot_timestamp[value]);
//       setTimeStamp(img);
//       setIsLoading(true);
// >>>>>>> develop
    }
  };

  const handleImageLoads = () => {
    setIsLoading(false);
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setImageError(true);
    setImageLoaded(false);
  };

  const handleClickGraph = (graphId) => {
    setSelectedGraph(graphId);
    setIsGraphReady(false); // Reset graph readiness when changing graphs
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const renderGraphComponent = () => {
    if (!sessionData || !selectedGraph) return null;

    const width = "60vw";
    const commonLayout = {
      X: 0,
      xanchor: "left",
      height: 240,
      width: width,
      margin: { r: 40, b: 95, t: 15, pad: 1 },
      xref: 450,
      borderRadius: 20,
      xaxis: {
        gridcolor: "gray",
        zeroline: false,
        line: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
        tickfont: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
        linecolor: currentTheme === "dark" ? "#FFFFFF" : "#000000",
        autorange: false,
        rangemode: "nonnegative",
        fixedrange: true,
        title: {
          text: "Time",
          font: {
            family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            size: 12,
            color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
          },
        },
        rangeslider: { visible: true }
      },
      yaxis: {
        gridcolor: "gray",
        zeroline: false,
        line: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
        fixedrange: true,
        tickfont: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
        linecolor: currentTheme === "dark" ? "#FFFFFF" : "#000000",
      },
      plot_bgcolor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
      paper_bgcolor: currentTheme === "dark" ? "#292E33" : "white",
      clickmode: "event+select",
    };

    let plotData = [];
    let yAxisTitle = "";

    switch (selectedGraph) {
      case 1:
        plotData = [{
          y: sessionData.cpu_app_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>CPU : %{y}(%)</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "CPU Usage (%)";
        break;
      case 2:
        plotData = [{
          y: sessionData.avg_gpu_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>GPU : %{y}(%)</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "GPU Usage (%)";
        break;
      case 3:
        plotData = [{
          y: sessionData.avg_memory_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>Memory : %{y}MB</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "Memory Usage (MB)";
        break;
      case 4:
        plotData = [{
          y: sessionData.averagefps_app_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>FPS : %{y}</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "FPS";
        break;
      case 5:
        plotData = [{
          y: sessionData.downloadddata_app_uage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>Downloaded : %{y}KiB</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "Data Downloaded (KiB)";
        break;
      case 6:
        plotData = [{
          y: sessionData.uploaddata_app_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>Uploaded : %{y}KiB</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "Data Uploaded (KiB)";
        break;
      case 7:
        plotData = [{
          y: sessionData.peak_memory_usage,
          text: sessionData.gpu_usage_time,
          hovertemplate: "<b>Peak Memory : %{y}MB</b><br><b>Time : %{text}</b>",
          showlegend: false,
          type: "scatter",
          mode: "lines+markers",
          line: { shape: "spline", smoothing: 0.8 },
          marker: { color: "#FEC400", size: "0" },
        }];
        yAxisTitle = "Peak Memory (MB)";
        break;
      case 8:
        // FPS Stability is a single value, so we'll show it differently
        return (
          <div style={{ 
            textAlign: "center", 
            padding: "20px",
            color: currentTheme === "dark" ? "white" : "black",
            fontSize: "18px"
          }}>
            FPS Stability: {sessionData.fps_stability}%
          </div>
        );
      case 9:
        // Battery Usage is not available, show message
        return (
          <div style={{ 
            textAlign: "center", 
            padding: "20px",
            color: currentTheme === "dark" ? "white" : "black",
            fontSize: "18px"
          }}>
            Battery Usage data not available
          </div>
        );
      default:
        return null;
    }

    commonLayout.yaxis.title = {
      text: yAxisTitle,
      font: {
        family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        size: 12,
        color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
      },
    };

    const handleSelected = (event) => {
      if (event.points && event.points[0]) {
        screenshot(sessionData.gpu_usage_time[event.points[0].pointIndex]);
      }
    };

    return (
      <div style={{ marginTop: "5vh" }}>
        <Plot
          data={plotData}
          layout={commonLayout}
          onClick={handleSelected}
          config={{ displayModeBar: false }}
        />
      </div>
    );
  };

  // Ensure the graph is ready after the chart data is prepared
  useEffect(() => {
    if (sessionData && selectedGraph) {
      const timer = setTimeout(() => {
        setIsGraphReady(true);
      }, 100); // Slight delay to ensure DOM is ready
      return () => clearTimeout(timer);
    }
  }, [sessionData, selectedGraph, xAxisParam]);

  const handleBatteryClick = () =>
    toast.error("Oops! No stats available!", {
      position: toast.POSITION.TOP_RIGHT,
    });

  return (
    <div style={{ width: "70%", marginTop: "8%" }}>
      <h3
        style={{
          textAlign: "left",
          marginLeft: "4%",
          color: currentTheme === "dark" ? "white" : "black",
        }}
      >
        Session Details
      </h3>
      <br />
      <div style={{ marginLeft: "-18vw" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            {/* Session Info */}
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="sessionContainers"
            >
              <div
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                }}
                className="icon-containers"
              >
                <PhoneAndroidIcon className="icons" style={{ marginLeft: "-0.5vw" }} />
              </div>
              <div style={{ width: "80%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "white" : "#293134",
                      }}
                      title={sessionData?.app_name}
                      className="container-fonts"
                    >
                      {sessionData?.app_name}
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      Version: <span style={{ fontWeight: "600" }}>{sessionData?.version_name}</span>
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      Last Updated: <span style={{ fontWeight: "600" }}>{sessionData?.created_at}</span>
                    </div>
                  </div>
                  <div>
                    <MoreVertIcon
                      onClick={handleClick}
                      sx={{ fontSize: 20, color: "#4779EF", cursor: "pointer", marginRight: "-10px" }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          backgroundColor: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                          color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                            backgroundColor: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        onClick={() => window.open(`https://gamemondev-service.indiumsoft.com/getReport?sessionID=${sessionData?.session_id}`)}
                      >

                        Download Report
                      </MenuItem>
                      <MenuItem onClick={opener}>Download Log File</MenuItem>
                    </Menu>

{/* //                         <MenuItem
//                           onClick={() => {
//                             if (Secondsessiondata?.session_id !== "") {

//                               window.open(

//                                 `https://gamemon-service.indiumsoft.com/getReport?sessionID=${Secondsessiondata?.session_id}`

//                               );
//                             }
//                           }}
//                           style={{
//                             fontSize: "14px",
//                             fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
//                             fontWeight: "400",
//                           }}
//                         >
//                           Download Report
//                         </MenuItem>
//                         <MenuItem
//                           onClick={opener}
//                           style={{
//                             fontSize: "14px",
//                             fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
//                             fontWeight: "400",
//                           }}
//                         >
//                           Download log File
//                         </MenuItem>
//                       </Menu>
//                     </>
// >>>>>>> develop */}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div
                    style={{ color: currentTheme === "dark" ? "#FFFFFF" : "#818E94" }}
                    className="version-fonts3"
                  >
                    Device:{" "}
                    <span style={{ fontWeight: "600", textTransform: "capitalize" }} title={sessionData?.device_name}>
                      {sessionData?.device_name?.length > 10
                        ? sessionData.device_name.substring(0, 8) + "..."
                        : sessionData?.device_name}
                    </span>
                  </div>
                  <div
                    style={{ color: currentTheme === "dark" ? "#FFFFFF" : "#818E94" }}
                    className="version-fonts3"
                  >
                    OS Version: <span style={{ fontWeight: "600" }}>{sessionData?.os_version}</span>
                  </div>
                  <div
                    style={{ color: currentTheme === "dark" ? "#FFFFFF" : "#818E94", marginRight: "10px" }}
                    className="version-fonts3"
                  >
                    GPU:{" "}
                    <span style={{ fontWeight: "600" }} title={sessionData?.gpu_vendor}>
                      {sessionData?.gpu_vendor?.includes(",") ? sessionData.gpu_vendor.split(",", 2).join(",") : sessionData?.gpu_vendor}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <br />

            {/* User Info */}
            <div
              style={{ backgroundColor: currentTheme === "dark" ? "#292E33" : "white" }}
              className="user-containers"
            >
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                  <div style={{ color: currentTheme === "dark" ? "#C5CACF" : "#818E94" }} className="user-fontss">
                    User
                  </div>
                  <div
                    style={{ marginTop: "10px", color: currentTheme === "dark" ? "#FFFFFF" : "#262626" }}
                    title={sessionData?.user_name}
                    className="user-fontss"
                  >
                    {sessionData?.user_name}
                  </div>
                </div>
                <div>
                  <div style={{ color: currentTheme === "dark" ? "#C5CACF" : "#818E94" }} className="user-fontss">
                    Session Title
                  </div>
                  <div
                    style={{ marginTop: "10px", color: currentTheme === "dark" ? "#FFFFFF" : "#262626" }}
                    title={sessionData?.sessionname}
                    className="user-fontss"
                  >
                    {sessionData?.sessionname}
                  </div>
                </div>
                <div>
                  <div style={{ color: currentTheme === "dark" ? "#C5CACF" : "#818E94" }} className="user-fontss">
                    Session Duration
                  </div>
                  <div
                    style={{ marginTop: "10px", color: currentTheme === "dark" ? "#FFFFFF" : "#262626" }}
                    className="user-fontss"
                  >
                    {sessionData?.total_duration ? formatTime(Number(sessionData.total_duration)) : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Session Notes */}
          <div
            style={{
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              color: currentTheme === "dark" ? "white" : "black",
              overflowY: "auto",
              overflowX: "hidden",
              marginLeft: "1%",
            }}
            className="sessionNote-Containers"
          >
            <h3 className="font-weight" style={{ margin: "5%" }}>
              Session Notes
            </h3>
            {notes.length > 0 && (
              <div>
                {notes.map((note, index) => (
                  <div key={index}>
                    <div style={{ marginLeft: "5%", marginTop: "5%" }}>
                      <span style={{ fontWeight: "bold" }}>{index + 1}) Title: </span>
                      <span style={{ fontWeight: "600", textTransform: "capitalize" }}>{note.session_notes_title}</span>
                    </div>
                    <div style={{ marginLeft: "5%", marginTop: "5px" }}>
                      <span style={{ fontWeight: "bold", marginLeft: "10%" }}>Description: </span>
                      <p
                        style={{
                          marginLeft: "12%",
                          textIndent: "-8%",
                          paddingLeft: "8%",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        • {note.session_notes}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Metric Grid */}
        <div>
          <div style={{ display: "flex", flexDirection: "column", width: "98%", marginLeft: "20vw" }} className="metric-gridss">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.cpu_average_usage || 0}
                  sharingwidth={"max"}
                  text="CPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 1}
                  onClick={() => handleClickGraph(1)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.gpu_average_usage || 0}
                  sharingwidth={"max"}
                  text="GPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 2}
                  onClick={() => handleClickGraph(2)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.memory_average_usage || 0}
                  sharingwidth={"max"}
                  text="Average Memory Usage"
                  unit="MB"
                  max={parseFloat(sessionData?.ram_size) * 1024 || 1000}
                  maxRef={sessionData?.ram_size || "NA"}
                  selected={selectedGraph === 3}
                  onClick={() => handleClickGraph(3)}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.average_fps_value || 0}
                  sharingwidth={"max"}
                  text="FPS"
                  unit=""
                  max={100}
                  selected={selectedGraph === 4}
                  onClick={() => handleClickGraph(4)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    sessionData?.download_data_usage_average > 1024
                      ? (parseFloat(sessionData.download_data_usage_average) / 1024).toFixed(2)
                      : parseFloat(sessionData?.download_data_usage_average || 0).toFixed(2)
                  }
                  sharingwidth={"max"}
                  text="Total Data Downloaded"
                  unit={sessionData?.download_data_usage_average > 1024 ? "MiB" : "KiB"}
                  max={sessionData?.download_data_usage_average > 1024 ? 100 : 1024}
                  selected={selectedGraph === 5}
                  onClick={() => handleClickGraph(5)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    sessionData?.upload_data_usage_average > 1024
                      ? (parseFloat(sessionData.upload_data_usage_average) / 1024).toFixed(2)
                      : parseFloat(sessionData?.upload_data_usage_average || 0).toFixed(2)
                  }
                  sharingwidth={"max"}
                  text="Total Data Uploaded"
                  unit={sessionData?.upload_data_usage_average > 1024 ? "MiB" : "KiB"}
                  max={sessionData?.upload_data_usage_average > 1024 ? 100 : 1024}
                  selected={selectedGraph === 6}
                  onClick={() => handleClickGraph(6)}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.peak_memory_value || 0}
                  sharingwidth={"max"}
                  text="Average Peak Memory"
                  unit="MB"
                  max={parseFloat(sessionData?.ram_size) * 1024 || 1000}
                  maxRef={sessionData?.ram_size || "NA"}
                  selected={selectedGraph === 7}
                  onClick={() => handleClickGraph(7)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={sessionData?.fps_stability || 0}
                  sharingwidth={"max"}
                  text="FPS Stability"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 8}
                  onClick={() => handleClickGraph(8)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={0}
                  sharingwidth={"max"}
                  text="Battery Usage"
                  unit="%"
                  max={100}
                  onClick={handleBatteryClick}
                />
              </div>
            </div>
          </div>

          {/* Screen Flow and Graph */}
          <div style={{ marginLeft: "21vw", display: "grid", gap: "1%", width: "100%", marginTop: "1rem" }}>
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "20vw",
                  height: "300px",
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  overflow: "hidden",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Screen Flow
                </p>
                <hr />
                <div style={{ float: "right", margin: "20px", marginTop: "10px" }}>
                  {timeNull !== null && (
                    <TravelExploreIcon
                      style={{ fontSize: "2rem", color: currentTheme === "dark" ? "white" : "black", cursor: "pointer" }}
                      onClick={handleZoomIconClick}
                    />
                  )}
                </div>
                <div style={{ height: "100%", maxWidth: "100%", textAlign: "center", padding: "10px" }}>
                  {isLoading && (
                    <div style={{ fontSize: "12px", color: currentTheme === "dark" ? "white" : "black" }}>
                      Fetching...
                    </div>
                  )}
                  {imageError && <div>Image Not Found!</div>}
                  <img
                    style={{
                      marginBottom: "1%",
                      borderRadius: "5px",
                      maxWidth: "100%",
                      height: "90%",
                      color: currentTheme === "dark" ? "white" : "black",
                      fontSize: "12px",
                    }}
                    src={timeStamp}
                    onLoad={handleImageLoads}
                    onError={handleImageError}
                    alt={timeNull !== null ? "Image Not Found!" : "Oops! Pick an Image!"}
                  />
                </div>
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 40,
                    left: -5,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(5px)",
                    zIndex: 999,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          style={{
                            height: "440px",
                            width: "220px",
                            borderRadius: "5px",
                            transform: `rotate(${rotationAngle}deg)`,
                            transition: "0.5s ease-in-out",
                          }}
                          src={selectedImage}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                    <div style={{ position: "absolute", top: -23, left: 130 }}>
                      <CloseIcon
                        style={{ fontSize: "2rem", color: currentTheme === "dark" ? "white" : "black", cursor: "pointer" }}
                        onClick={closeModal}
                      />
                    </div>
                    <ScreenRotationIcon
                      style={{ fontSize: "2rem", color: currentTheme === "dark" ? "white" : "black", cursor: "pointer", marginTop: "5px" }}
                      onClick={rotateImage}
                    />
                  </div>
                </div>
              )}
            </div>
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "60vw",
                  height: "300px",
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "2%",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Selected Graph
                </p>
                <hr />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  {renderGraphComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        toastStyle={{
          marginTop: "4rem",
          borderRadius: "10px",
          backgroundColor: "white",
          alignContent: "center",
          height: 50,
          margin: 10,
          width: 300,
          textAlign: "center",
        }}
        closeButton={false}
        hideProgressBar={false}
        autoClose={2000}
        position="top-center"
      />
    </div>
  );
}