import SharingHeader from "./components/Header/Sharing Header";
import HomeWrapper from "./components/Header/Pages/Home/HomeWrapper";
import SuperAdminDashboard from "./components/Header/Pages/Home/SuperAdminDashboard";
import OrgUsagePage from "./components/Header/Pages/Home/OrgUsagePage.js";
import AllSession from "./components/Header/Pages/AllSession/AllSession";
import Admin from "./components/Header/Pages/Admin/Admin";
import Projects from "./components/Header/Pages/Projects/Projects";
import Sharing from "./components/Header/Pages/Home/Sharing";
import Sessionmain from "./components/Header/Pages/Sessions/Sessions";
import Profile from "./components/Header/Pages/Profile/Profile";
import { useContext } from "react";
import AuthContext from "./hooks/useAuth.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./components/ProtectedRoute.js";
import Login from "./components/Login/Login";
import ResetPass from "./components/Login/ResetPassword.js";
import SessionComparison from "./components/Header/Pages/SessionCompare/SessionCompare";
import Layout from "./components/Header/Pages/Layout";
import Settings from "./components/Header/Pages/Settings/Settings";
import Adminwrapper from "./components/Header/Pages/Admin/Adminwrapper";
import SuperAdminwrapper from "./components/Header/Pages/SuperAdmin/SuperAdminwrapper.js";
import ForgotPassword from "./components/Login/ForgetPassword.js";
import CreatePassword from "./components/Login/CreatePassword.js";
import SuperUserAdminWrapper from "./components/Header/Pages/SuperAdmin/user page/SuperUserAdminWrapper.js";
import SessionComparision2 from "./components/Header/Pages/SuperAdmin/session page/SessionComparision2.js";
import OrganizationDashboardSuperAdmin from "./components/Header/Pages/SuperAdmin/dashboard/OrganizationDashboardSuperAdmin.js";

function App() {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth.isLoggedIn;
  const role = auth.role;

  return (
    <Router>
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route exact path="/PasswordReset" element={<ResetPass />} />
            <Route exact path="/ForgotPassword/:token" element={<ForgotPassword />} />
            <Route exact path="/createPassword/:token" element={<CreatePassword />} />
          </>
        ) : (
          <>
            {/* Admin Routes */}
            {role === "admin" && (
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate replace to="/Dashboard" />} />
                <Route exact path="/Dashboard" element={<HomeWrapper />} />
                <Route exact path="/Users" element={<Adminwrapper />} />
                <Route path="/Users/*" element={<Admin />} />
                <Route element={<PrivateRoute allowedRoles={["admin"]} />}>
                  <Route exact path="/Projects" element={<Projects />} />
                  <Route exact path="/SessionComparison" element={<SessionComparison />} />
                  <Route exact path="/Sessions" element={<AllSession />} />
                  <Route exact path="/Settings" element={<Settings />} />
                  <Route exact path="/Profile" element={<Profile />} />
                </Route>
              </Route>
            )}

            {/* Super Admin Routes */}
            {role === "super_admin" && (
              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate replace to="/Dashboard" />} />
                <Route exact path="/Dashboard" element={<OrganizationDashboardSuperAdmin />} />
                <Route exact path="/SuperAdmin" element={<SuperAdminwrapper />} />
                <Route path="/Invoice/*" element={<SuperAdminDashboard />} />
                <Route exact path="/SessionComparision" element={<SessionComparision2 />} />
                <Route path="/Users" element={<SuperUserAdminWrapper />} />
                <Route path="/Usage/*" element={<OrgUsagePage/>} />

                <Route element={<PrivateRoute allowedRoles={["super_admin"]} />}>
                  <Route exact path="/Projects" element={<Projects />} />
                  <Route exact path="/SessionComparison" element={<SessionComparison />} />
                  <Route exact path="/Sessions" element={<AllSession />} />
                  <Route exact path="/Settings" element={<Settings />} />
                  <Route exact path="/Profile" element={<Profile />} />

                </Route>
              </Route>
            )}
          </>
        )}
        
      {/* Tester Routes */}
    {role === "tester" && (
    <Route path="/" element={<Layout />}>
    <Route path="/" element={<Navigate replace to="/Dashboard" />} />
    <Route exact path="/Dashboard" element={<HomeWrapper />} />
    <Route exact path="/SessionComparison" element={<SessionComparison />} />
    <Route exact path="/Sessions" element={<AllSession />} />
    <Route exact path="/Settings" element={<Settings />} />
    <Route exact path="/Profile" element={<Profile />} />

    </Route>
    )}
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/" element={<SharingHeader />}>
          <Route exact path="/shareSessionDetails/*" element={<Sharing />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
