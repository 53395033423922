import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../../../axios/index";
import { ThemePreferenceContext } from "../../../../index";
import AuthContext from "../../../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Chip from "@mui/material/Chip";
import "react-toastify/dist/ReactToastify.css";
import "./OrgUsage.css";
import { DataGrid } from "@mui/x-data-grid";

function OrgUsagePage() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const { currentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    getUsers();
  }, []);

  // Fetch user data from the API
  const getUsers = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get("/organization_info", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("API Response for users:", response.data.data); // Log the full response
      const data = response.data.data || [];
      // Check for duplicate org_ids
      const orgIds = data.map((item) => item.org_id);
      const duplicates = orgIds.filter((id, index) => orgIds.indexOf(id) !== index);
      console.log("Duplicate org_ids:", duplicates);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching organization information:", error);
      setUsers([]); // Set to empty array on error
      setTimeout(() => getUsers(), 1000); // Retry after 1 second
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Prepare row data from API response
  const rowData = users?.map((data) => ({
    id: data?.org_id || "",
    org_name: data?.org_name || "",
    total_seconds: data?.total_seconds || 0,
    organization_count: data?.organization_count || 0,
    invoice_status: data?.latest_status || "Not Generated", // Default to "Not Generated"
    latest_invoice_id: data?.latest_invoice_id || "",
    latest_tax_id: data?.latest_tax_id || "",
  }));

  // Filter rows based on search term
  const filteredData = rowData
    .filter((data) =>
      data.org_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((data) => data.id && data.org_name); // Ensure required fields exist

  // Capitalize first letter of each word
  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'org_name',
      headerName: 'Organization Name',
      width: 200,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      field: 'total_seconds',
      headerName: 'Total Hours Spend',
      width: 170,
      valueFormatter: (params) => {
        const seconds = params.value;
        if (seconds != null && !isNaN(seconds)) {
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          return `${hours} hr ${minutes} min`;
        }
        return "0 hr 0 min";
      },
    },
    {
      field: 'organization_count',
      headerName: 'No of Users',
      width: 150,
    },
    {
      field: 'invoice_status',
      headerName: 'Invoice Status',
      width: 170,
      renderCell: (params) => {
        const status = params.value;
    
        if (!status) {
          return (
            <Chip
              label="No Data Found"
              color="error"
              style={{ 
                borderRadius: "5px",
                width: "120px", // Fixed width for all chips
                justifyContent: "center"
              }}
              variant="outlined"
            />
          );
        }
    
        let chipColor = "default";
        let chipLabel = status;
    
        if (status === "Generated") {
          chipColor = "success";
        } else if (status === "Not Generated") {
          chipColor = "warning";
        }
    
        return (
          <Chip
            label={chipLabel}
            color={chipColor}
            style={{ 
              borderRadius: "5px",
              width: "120px", // Fixed width for all chips
              justifyContent: "center"
            }}
            variant="outlined"
          />
        );
      },
    },
    {
      field: 'latest_tax_id',
      headerName: 'Tax ID',
      width: 150,
    },
  ];

  return (
    <>
      <div style={{ width: "80vw", marginTop: "2.75%", position: "fixed" }}>
        <main>
          <div
            style={{
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",

              borderRadius: '8px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              marginRight: "10px",
              maxHeight: '100vh',
            }}
          >
            {loading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                Loading organization data...
              </div>
            ) : users.length === 0 ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                No data available. Please try again later.
              </div>
            ) : (
              <div style={{ height: "83vh" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0 12px",
                    paddingTop: "5px",
                  }}
                >
                  <div>
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder={"Search Organization"}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{
                                color:
                                  currentTheme === "dark" ? "#99999F" : "#818E94",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        height: "40px",
                        "& input::placeholder": {
                          textOverflow: "ellipsis !important",
                          color: currentTheme === "dark" ? "#99999F" : "#818E94",
                          opacity: 1,
                        },
                        "& .MuiInputBase-root": {
                          height: "40px !important",
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          borderRadius: "8px",
                          color: currentTheme === "dark" ? "#99999F" : "#000000",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <br />
                <div
                  style={{
                    height: "75vh",
                    border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    overflowX: "hidden" // Add this line
                  }}
                >
                  <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    headerHeight={45}  // Added this to reduce header height
                    rowHeight={45} 

                    disableSelectionOnClick
                    sx={{
                      '& .MuiDataGrid-cell': {
                        fontSize: '15px',
                        color: currentTheme === "dark" ? "white" : "#174A84",
                        padding: '0 16px',
                        fontWeight: 400,
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`, // Reduce cell padding
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        '& .MuiDataGrid-columnHeader': {
                          '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 700,
                            fontSize: '13px',
                            color: currentTheme === "dark" ? "white" : "#174A84",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                          }
                        }
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: 'transparent !important',
                      },
                      '& .MuiDataGrid-cell.Mui-selected': {
                        backgroundColor: 'transparent !important',
                      },
                      '& .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: 'transparent !important',
                      },
                      '& .MuiDataGrid-row': {  // Add styling for rows
                        minHeight: '45px !important',  // Ensure consistent row height
                        maxHeight: '45px !important',
                      },
                      '& .MuiDataGrid-footerContainer': {
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        display: 'flex',
                        borderTop: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                        '& .MuiTablePagination-root': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                        },
                        '& .MuiTablePagination-selectLabel': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                          fontSize: '13px',
                          fontWeight: 500,
                        },
                        '& .MuiTablePagination-displayedRows': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                          fontSize: '13px',
                          fontWeight: 500,
                        },
                        '& .MuiTablePagination-select': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                        },
                        '& .MuiTablePagination-selectIcon': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                        },
                        '& .MuiIconButton-root': {
                          color: currentTheme === "dark" ? "white" : "#174A84",
                        },
                        '& .MuiIconButton-root.Mui-disabled': {
                          color: currentTheme === "dark" ? "#646667" : "#DFDFDF",
                        },
                      },
    
                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                      color: currentTheme === "dark" ? "white" : "black",
                    }}

      />
                </div>
              </div>
            )}
          </div>
          <ToastContainer
            toastStyle={{
              marginTop: "4rem",
              borderRadius: "10px",
              backgroundColor: "white",
              alignContent: "center",
              height: 50,
              margin: 10,
              width: 300,
              closeOnClick: true,
              textAlign: "center",
              position: "fixed",
              zIndex: 1050,
            }}
            closeButton={false}
            hideProgressBar={true}
            autoClose={500}
            position="top-center"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </main>
      </div>
    </>
  );
}

export default OrgUsagePage;