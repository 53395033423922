import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  token: "",
  id: "",
  org_id: "",
  role: "",
  name: "",
  email: "",
  organization_name: "",
  selectedUser: {
    id: "",
    name: "",
  },
  selectedOrganization: {
    org_id: "",
    org_name: "",
  },
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  userId: () => {},
  compDataHandler: () => {},
  setSelectedUser: () => {},
  setSelectedOrganization: () => {},
  compData: "",
});

export const AuthContextProvider = (props) => {
  // Existing session storage items
  const initialToken = sessionStorage.getItem("token");
  const initialId = sessionStorage.getItem("id");
  const OrgId = sessionStorage.getItem("org_id");
  const initialRole = sessionStorage.getItem("role");
  const userName = sessionStorage.getItem("name");
  const emailId = sessionStorage.getItem("email");
  const OrgName = sessionStorage.getItem("organization_name");

  // State management
  const [token, setToken] = useState(initialToken);
  const [userInfo, setUserInfo] = useState({});
  const [role, setRole] = useState(initialRole);
  const [id, setId] = useState(initialId);
  const [orgId, setOrgId] = useState(OrgId);
  const [name, setName] = useState(userName);
  const [email, setEmail] = useState(emailId);
  const [orgName, setorgName] = useState(OrgName);
  const [compData, setCompData] = useState(null);

  // Enhanced selectedUser state with persistence
  const [selectedUser, setSelectedUser] = useState(() => {
    const savedUser = sessionStorage.getItem("selectedUser");
    return savedUser ? JSON.parse(savedUser) : { id: "", name: "" };
  });

  // Add selectedOrganization state with persistence from localStorage
  const [selectedOrganization, setSelectedOrganization] = useState(() => {
    const savedOrg = localStorage.getItem("selectedOrganization");
    return savedOrg ? JSON.parse(savedOrg) : { org_id: "", org_name: "" };
  });

  // Persist selectedUser to sessionStorage whenever it changes
  useEffect(() => {
    if (selectedUser.id) {
      sessionStorage.setItem("selectedUser", JSON.stringify(selectedUser));
    }
  }, [selectedUser]);

  // Persist selectedOrganization to localStorage whenever it changes
  useEffect(() => {
    if (selectedOrganization.org_id) {
      localStorage.setItem("selectedOrganization", JSON.stringify(selectedOrganization));
    }
  }, [selectedOrganization]);

  const selectedUserHandler = (user) => {
    setSelectedUser(user);
  };

  const selectedOrganizationHandler = (org) => {
    setSelectedOrganization(org);
  };

  const userIsLoggedIn = !!token;

  const loginHandler = (data) => {
    setUserInfo(data);
    setToken(data.token);
    setRole(data.role);
    setId(data.id);
    setOrgId(data.org_id);
    setName(data.user_Name);
    setEmail(data.email);
    setorgName(data.org_name);

    // Store in session storage
    sessionStorage.setItem("token", data.token);
    sessionStorage.setItem("id", data.id);
    sessionStorage.setItem("org_id", data.org_id);
    sessionStorage.setItem("role", data.role);
    sessionStorage.setItem("name", data.user_Name);
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("organization_name", data.org_name);
  };

  const compDataHandler = (data) => {
    setCompData(data);
  };

  const userIdHandler = (id) => {
    setId(id);
  };

  const logoutHandler = () => {
    // Clear all session storage
    sessionStorage.clear();
    // Clear localStorage items related to our app
    localStorage.removeItem("selectedOrganization");
    
    // Reset all state
    setToken(null);
    setRole(null);
    setName(null);
    setEmail(null);
    setorgName(null);
    setId(null);
    setOrgId(null);
    setSelectedUser({ id: "", name: "" });
    setSelectedOrganization({ org_id: "", org_name: "" });
    setUserInfo({});
    setCompData(null);
  };

  const contextValue = {
    token,
    isLoggedIn: userIsLoggedIn,
    userInfo,
    role,
    id,
    org_id: orgId,
    compData,
    login: loginHandler,
    logout: logoutHandler,
    settinguserInfo: setUserInfo,
    userId: userIdHandler,
    name,
    email,
    organization_name: orgName,
    compDataHandler,
    selectedUser,
    setSelectedUser: selectedUserHandler,
    selectedOrganization,
    setSelectedOrganization: selectedOrganizationHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;