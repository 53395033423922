import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid } from "@material-ui/core";
import FormEditDialog from "../../Admin/Dialog";
import FormAddDialog from "../../Admin/DialogAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../../../../axios/index";
import "./UserSuperAdmin.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField, InputAdornment } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../../index";
import AuthContext from "../../../../../hooks/useAuth";
import { DataGrid } from '@mui/x-data-grid';

function UserSuperAdminInactive() {
  const [users, SetUsers] = useState([]);
  const [openDel, setOpenDel] = React.useState(false);
  const auth = useContext(AuthContext);
  // const onChanges = (e) => {
  //   const enteredEmail = e.target.value;
  //   setIsValidEmail(enteredEmail.includes('@'));
  //   // ... rest of your onChange logic
  // };
  // var randomNum = Math.floor(Math.random() * (99 - 10 + 1)) + 10;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const initialValue = {
    org_id: "",
    name: "",
    email: "",
    password: null,
    phone_number: "1234509876",
    role: "",
    access_end_date: "",
    access_start_date: "",
  };
  const [gridApi, setGridApi] = useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState(initialValue);

  // New states for organization filter
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState(null);
  const [orgSearchTerm, setOrgSearchTerm] = useState('');
  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);


  useEffect(() => {
    // First, try to get the organization from localStorage when component mounts
    const savedOrg = localStorage.getItem('selectedOrganization');

    if (savedOrg && !selectedOrgs) {
      // Parse the saved org and set it in state
      const parsedOrg = JSON.parse(savedOrg);
      setSelectedOrgs(parsedOrg);
      // Note: We don't need to call fetchUsers here because the selectedOrgs dependency
      // in the second useEffect will trigger a fetch when this state updates
    }
  }, []);


  // Fetch organizations
  useEffect(() => {
    axios
      .get("/organization", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setOrganizations(res.data.data);
      })
      .catch((error) => console.error("Failed to fetch organizations:", error));
  }, [auth.token]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOrgDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  // Filter organizations

  // Handle key press for auto-search
  const filteredOrganizations = organizations
    .filter((org) => org.status_org === "Y") // Filter only active organizations
    .sort((a, b) => {
      const aMatches = a.org_name.toLowerCase().startsWith(orgSearchTerm.toLowerCase());
      const bMatches = b.org_name.toLowerCase().startsWith(orgSearchTerm.toLowerCase());
      if (aMatches && !bMatches) return -1; // a comes first
      if (!aMatches && bMatches) return 1; // b comes first
      return 0; // no change in order
    });
  // Handle organization selection
  const handleOrgSelect = (org) => {
    setSelectedOrgs(org);
    setIsOrgDropdownOpen(false);
    localStorage.setItem('selectedOrganization', JSON.stringify(org));

  };

  // Handle key press for auto-search
  const handleKeyPress = (event) => {
    if (event.key === 'Backspace') {
      setOrgSearchTerm((prev) => prev.slice(0, -1)); // Remove last character
    } else if (event.key.length === 1) { // Only process single character keys
      setIsOrgDropdownOpen(true);
      setOrgSearchTerm((prev) => prev + event.key); // Append the key to the search term
    }
  };

  // Fetch users based on selected organizations
  // Fetch users based on selected organizations
  const fetchUsers = async (orgId) => {
    if (!orgId) {
      SetUsers([]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://gamemondev-service.indiumsoft.com/users?org_id=${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      const data = await response.json();

      // Process users consistently
      const processedUsers = (data.data || []).map(user => ({
        ...user,
        // Keep original status from API
        original_status: user.status,
        // Calculate access status
        status: new Date(user.access_end_date) > new Date() ? 'Active' : 'Inactive',
        // Calculate days left
        number_of_days_left: calculateDaysLeft(user.access_end_date)
      }));

      SetUsers(processedUsers);
    } catch (error) {
      setError("Failed to fetch users");
      console.error("Failed to fetch users:", error);
      SetUsers([]);
    } finally {
      setLoading(false);
    }
  };

  // Use the fetch function in useEffect
  useEffect(() => {
    if (selectedOrgs) {
      fetchUsers(selectedOrgs.org_id);
    }
  }, [selectedOrgs, auth.token]);





  const filteredUsers = useMemo(() => {
    if (!users || users.length === 0) return [];

    return users.filter(user => {
      const endDate = new Date(user.access_end_date);
      const today = new Date();

      // We want users who:
      // 1. Have expired access (status is 'Inactive')
      // 2. Original status was active/valid
      return user.status === 'Inactive' &&
        user.original_status?.toLowerCase() === 'active';
    }).filter(user =>
      searchTerm ?
        Object.values(user)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        : true
    );
  }, [users, searchTerm]);

  // Helper function for calculating days left
  const calculateDaysLeft = (endDate) => {
    const end = new Date(endDate);
    const today = new Date();
    const diffTime = Math.ceil((end - today) / (1000 * 60 * 60 * 24));
    return diffTime;
  };

  // Add this console.log to debug
  useEffect(() => {
    console.log('Current users:', users);
    console.log('Filtered users:', filteredUsers);
  }, [users, filteredUsers]);





  // Filter users based on search term and number of days left



  const paginationNumberFormatter = useCallback((params) => {
    // return '[' + params.value.toLocaleString() + ']';
    return params.value;
  }, []);


  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    setFormData(initialValue);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setFormData(initialValue);
  };

  const handleClickDelOpen = () => {
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);

  const columnDefs = useMemo(() => [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      unSortIcon: true,
      valueFormatter: (params) => params.value ? params.value.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ") : "",
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1.5,
      minWidth: 200,
      maxWidth: 300,
      unSortIcon: true,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      unSortIcon: true,
      valueFormatter: (params) => params.value ? params.value.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ") : "",
    },
    {
      headerName: "Date Joined",
      field: "access_start_date",
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
      unSortIcon: true,
    },
    {
      headerName: "Days Left",
      field: "number_of_days_left",
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      unSortIcon: true,
    },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <EditIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => {
              handleUpdate(params.data, params.value);
            }}
          ></EditIcon>
          <DeleteIcon
            variant="outlined"
            color="secondary"
            style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
            onClick={(e) => {
              setUpdateId(params.value);
              handleClickDelOpen();
            }}
          ></DeleteIcon>
        </div>
      ),
      suppressNavigable: true,
      suppressCellFocus: true
    },
  ], []);



  const onEmail = (e) => {
    const { value, id } = e.target;
    if (value.includes("@") && value.includes(".")) {
      setFormData({ ...formData, [id]: value });
    }
  };

  const onChange = (e) => {
    const { value, id } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const onRoleChange = (e) => {
    setFormData({ ...formData, role: e.target.value });
  };
  const onDateChange = (e) => {
    setFormData({ ...formData, access_end_date: e.target.value });
  };

  const onGridReady = (params) => {
    setGridApi(params);
  };

  //setting update row data to form data and opening pop up window
  const handleUpdate = (oldData, id) => {
    setUpdateId(id);
    // Create a copy of oldData without modifying the original
    const formDataCopy = {
      ...oldData,
      // Only include the fields we want to edit
      org_id: oldData.org_id,
      name: oldData.name,
      email: oldData.email,
      role: oldData.role,
      access_end_date: oldData.access_end_date,
      access_start_date: oldData.access_start_date,
      phone_number: oldData.phone_number || "1234509876"
    };

    setFormData(formDataCopy);
    handleEditOpen();
  };
  //deleting a user
  const handleDelete = () => {
    handleDelClose();
    axios
      .delete("/user", {
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        toast.error("Deleted Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        // Replace getUsers with fetchUsers
        if (selectedOrgs) {
          fetchUsers(selectedOrgs.org_id);
        }
      });
  };

  const handleFormAddSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .post("/registerUser", values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleAddClose();
        // Replace getUsers with fetchUsers
        if (selectedOrgs) {
          fetchUsers(selectedOrgs.org_id);
        }
        toast.success("User Added Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Enter all credentails !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const handleFormEditSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .put("/user", values, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        handleEditClose();
        // Replace getUsers with fetchUsers
        if (selectedOrgs) {
          fetchUsers(selectedOrgs.org_id);
        }
        toast.success("User Edited Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Elements are Missing !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  // Add columns configuration for MUI DataGrid
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      maxWidth: 200,
      valueFormatter: (params) => params.value ? capitalizeFirstLetter(params.value) : "",
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
      minWidth: 200,
      maxWidth: 300,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      valueFormatter: (params) => params.value ? capitalizeFirstLetter(params.value) : "",
    },
    {
      field: 'access_start_date',
      headerName: 'Date Joined',
      flex: 1,
      minWidth: 120,
      maxWidth: 150,
    },
    {
      field: 'number_of_days_left',
      headerName: 'Days Left',
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <div>
          <EditIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => handleUpdate(params.row, params.row.id)}
          />
          <DeleteIcon
            variant="outlined"
            color="secondary"
            style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
            onClick={() => {
              setUpdateId(params.row.id);
              handleClickDelOpen();
            }}
          />
        </div>
      ),
    }
  ];

  return (
    <>
      <div style={{ width: "81vw" }}>
        <main>
          <div
            className="ag-theme-alpine"
            style={{
              height: "80vh",
              marginTop: "-3%",
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
            }}
          >

            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "30px 12px",
                paddingTop: "20px",
                gap: "20px",
              }}
            >
              {/* Organization Filter Dropdown */}
              <div style={{ position: 'relative' }} ref={dropdownRef}>
                <div
                  onClick={() => setIsOrgDropdownOpen(!isOrgDropdownOpen)}
                  onKeyDown={handleKeyPress}
                  tabIndex="0"
                  style={{
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    color: currentTheme === "dark" ? "white" : "black",
                    border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    cursor: "pointer",
                    width: "200px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <span style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: "12px"
                  }}>
                    {selectedOrgs ? selectedOrgs.org_name : "Select Organization"}
                  </span>
                  <span>▼</span>
                </div>

                {isOrgDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 1000,
                      backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                      border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                      borderRadius: "8px",
                      width: "200px",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                      marginTop: "4px"
                    }}
                  >
                    <div style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "15px"
                    }}>
                      {filteredOrganizations.map((org) => (
                        <div
                          key={org.org_id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px",
                            cursor: "pointer",
                            backgroundColor: org.org_name.toLowerCase().startsWith(orgSearchTerm.toLowerCase())
                              ? currentTheme === "dark" ? "#1E1E1E" : "#F0F0F0" // Highlight matching organizations
                              : currentTheme === "dark" ? "#292E33" : "white",
                            color: currentTheme === "dark" ? "white" : "black",
                            fontSize: "12px"
                          }}
                          onClick={() => handleOrgSelect(org)}
                        >
                          <span>{org.org_name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Grid>

            {/* Error Message */}
            {error && (
              <div style={{
                backgroundColor: "#FEE2E2",
                color: "#DC2626",
                padding: "12px",
                borderRadius: "8px",
                margin: "16px 12px"
              }}>
                {error}
              </div>
            )}

            {/* data Grid Component */}
            <div
              style={{
                height: "calc(100% - 80px)",
                width: "100%",
                marginTop: "20px",
                boxShadow: "0px 25px 30px #0000000A",
                border: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
              }}
              className={currentTheme === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
            >
              <DataGrid
                rows={filteredUsers}
                columns={columns}
                pageSize={8}
                rowHeight={45}
                headerHeight={45}
                rowsPerPageOptions={[8]}
                disableSelectionOnClick
                disableColumnMenu
                hideFooterSelectedRowCount
                sx={{
                  '& .MuiDataGrid-cell': {
                    fontSize: '15px',
                    color: currentTheme === "dark" ? "white" : "#174A84",
                    padding: '0 16px',
                    fontWeight: 400,
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    '& .MuiDataGrid-columnHeader': {
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                        fontSize: '13px',
                        color: currentTheme === "dark" ? "white" : "#174A84",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                      }
                    }
                  },
                  '& .MuiDataGrid-row': {
                    minHeight: '45px !important',
                    maxHeight: '45px !important',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'transparent',
                  },
                  '& .MuiDataGrid-row.Mui-selected': {
                    backgroundColor: 'transparent !important',
                  },
                  '& .MuiDataGrid-cell.Mui-selected': {
                    backgroundColor: 'transparent !important',
                  },
                  '& .MuiDataGrid-row.Mui-selected:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  // In your DataGrid sx prop, update the footerContainer styling:

                  '& .MuiDataGrid-footerContainer': {
                    backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                    color: currentTheme === "dark" ? "white" : "black",
                    display: 'flex',
                    borderTop: currentTheme === "dark" ? "1px solid #646667" : "1px solid #DFDFDF",
                    '& .MuiTablePagination-root': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                    },
                    '& .MuiTablePagination-selectLabel': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                      fontSize: '13px',
                      fontWeight: 500,
                    },
                    '& .MuiTablePagination-displayedRows': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                      fontSize: '13px',
                      fontWeight: 500,
                    },
                    '& .MuiTablePagination-select': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                    },
                    '& .MuiTablePagination-selectIcon': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                    },
                    '& .MuiIconButton-root': {
                      color: currentTheme === "dark" ? "white" : "#174A84",
                    },
                    '& .MuiIconButton-root.Mui-disabled': {
                      color: currentTheme === "dark" ? "#646667" : "#DFDFDF",
                    },
                  },
                  backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
                  color: currentTheme === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>

          {/* Dialogs */}
          <FormEditDialog
            open={openEdit}
            handleClose={handleEditClose}
            data={formData}
            onChange={onChange}
            onEmail={onEmail}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            updateId={updateId}
            handleFormSubmit={handleFormEditSubmit}
          />
          <FormAddDialog
            open={openAdd}
            handleClose={handleAddClose}
            data={formData}
            onChange={onChange}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            onEmail={onEmail}
            updateId={updateId}
            handleFormSubmit={handleFormAddSubmit}
          />

          {/* Delete Dialog */}
          <Dialog
            PaperProps={{
              sx: {
                width: "425px",
                height: "225px",
              }
            }}
            open={openDel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}>
              <DialogTitle
                style={{
                  fontSize: "20px",
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                  sans-serif`,
                }}
              >
                Delete User
              </DialogTitle>
              <CancelIcon
                onClick={handleDelClose}
                style={{
                  marginTop: "4.3%",
                  cursor: "pointer",
                  marginRight: "2.5vh",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              />
            </div>
            <Divider style={{ margin: "0vw 1.5vw", width: "80%" }} />
            <DialogContent>
              <h4 style={{ color: "grey" }}>
                Are you sure, you want to delete the user?
              </h4>
            </DialogContent>
            <DialogActions style={{
              marginLeft: "1vw",
              display: "flex",
              justifyContent: "row",
              marginRight: "5vw",
              marginTop: "125px",
              position: "fixed",
              gap: "20px",
            }}>
              <List>
                <div style={{
                  color: "#FFFFFF",
                  marginTop: "8%",
                  background: "#4779EF",
                  borderRadius: "8px",
                  width: "160px",
                  height: "40px",
                  padding: "3px",
                  fontSize: "15px",
                }}>
                  <ListItem
                    button
                    onClick={handleDelete}
                    style={{
                      paddingLeft: "30%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                  >
                    Proceed
                  </ListItem>
                </div>
              </List>
              <List>
                <div style={{
                  color: "#4779EF",
                  marginTop: "8%",
                  border: "2px solid #4779EF",
                  borderRadius: "8px",
                  width: "160px",
                  height: "40px",
                  padding: "7px",
                  fontSize: "15px",
                }}>
                  <ListItem
                    button
                    onClick={handleDelClose}
                    style={{
                      paddingLeft: "30%",
                      marginTop: "-4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                  >
                    Cancel
                  </ListItem>
                </div>
              </List>
            </DialogActions>
          </Dialog>

          <ToastContainer
            toastStyle={{
              marginTop: "4rem",
              borderRadius: "10px",
              backgroundColor: "white",
              alignContent: "center",
              height: 50,
              margin: 10,
              width: 300,
              closeOnClick: true,
              textAlign: "center",
            }}
            closeButton={false}
            hideProgressBar={false}
            autoClose={3000}
            position="top-center"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </main>
      </div>
    </>
  );
}
export default UserSuperAdminInactive;