import React from "react";
import MetricCpuMain from "./MetricCpuMain.js";
import MetricGpuMain from "./MetricGpuMain.js";
import MetricFpsMain from "./MetricFpsMain.js";
import MetricMemory from "./MetricMemory";
import MetricUploadData from "./MetricUploadData";
import MetricDownloadData from "./MetricDownloadData";
import MetricAppPower from "./MetricAppPower";

export default function SessionMain(props) {
  let theme = props.currentTheme;
  let selectedComp = props.selectedOption;
  let uid = props.User_id;

  // No-op function to avoid errors if senddata1 is called
  const noOp = () => {};

  const renderGraph = () => {
    const mappingComp = {
      "CPU Usage": <MetricCpuMain theme={theme} uid={uid} senddata1={noOp} />,
      "GPU Usage": <MetricGpuMain theme={theme} uid={uid} senddata1={noOp} />,
      "Avg Memory": <MetricMemory theme={theme} uid={uid} senddata1={noOp} />,
      "FPS": <MetricFpsMain theme={theme} uid={uid} senddata1={noOp} />,
      "Downloaded Data": <MetricDownloadData theme={theme} uid={uid} senddata1={noOp} />,
      "Uploaded Data": <MetricUploadData theme={theme} uid={uid} senddata1={noOp} />,
      "AppPowerData": <MetricAppPower theme={theme} uid={uid} senddata1={noOp} />,
    };

    return mappingComp[selectedComp];
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ pointerEvents: "none" }}>
        {renderGraph()}
      </div>
    </div>
  );
}